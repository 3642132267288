import _ from 'lodash'
import { bindObjectFunctions } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import RemoteApi from '../../panels/commons/remote-api'
import CoreApi from '../core/core-api'
import { editorAppMetaData } from './editor-app'
import { fetchAddPanelPresets, fetchFormsThemes } from '../core/preset/themes-service'
import ExperimentsService from '../../utils/experiments-service'
import { FormPreset } from '../../constants/form-types'
import { EVENTS } from '../../constants/bi'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { updateMetaSiteId, initMonitoring, handleError, captureBreadcrumb } from './monitoring'
import { FormsServiceAmbassadorClient } from '../../panels/commons/formsServiceAmbassadorClient'
import { PanelName } from '../../constants/panel-names'
import { LocaleData } from '@wix/forms-common'
import { createI18n } from '../../utils/i18n'
import { initCollectionsApi } from '../core/wixCodeCollectionApi'

// start app functions
const startApp = async (origin: Origin) => {
  const api = await editorAppMetaData.getCoreApi()
  const originType = _.get(origin, 'info.type')
  api.setOriginType(originType)

  if (originType === 'APP_MARKET') {
    api.managePanels.openModalPanel(null, PanelName.ADD_FORM, () =>
      api.log({ evid: EVENTS.PANELS[PanelName.ADD_FORM].OPEN_PANEL }),
    )
    return
  }

  let preset = _.get(origin, 'info.preset')

  if (!preset) {
    return
  }

  if (preset === FormPreset.REGISTRATION_FORM) {
    preset = await api.addForm.getRegistrationFormPreset()
  }

  const containerRef = origin.info.containerRef

  if (originType === 'ADD_PANEL') {
    if (await api.addForm.preventFormAdditionAddPanel(preset, containerRef)) {
      return
    }

    return api.addForm.addForm(preset, {
      containerRef: origin.info.containerRef,
      source: originType,
      shouldSelectForm: _.get(origin, 'info.select_form', false),
    })
  }

  await api.addForm.addForm(preset, {
    containerRef,
    source: originType,
    createCollection: false,
    targetPageRef: _.get(origin, 'info.targetPageRef'),
    shouldSelectForm: _.get(origin, 'info.select_form'),
  })

  // for custom signup or other external flows do not save the site, the owner of the flow will do it if needed
  // for editor 2 flow do not save site at the end the site creation will do.
  if (originType === 'PAGE_SETTINGS_PANEL' || api.isEditor2Initiator()) {
    return Promise.resolve()
  }

  return api.saveSite()
}

// init functions
const initExperiments = async () => {
  const experiments: Experiments = new Experiments()
  await experiments.load('wix-form-builder')

  ExperimentsService.init(experiments)
  return experiments
}
const initTranslations = async ({
  boundEditorSDK,
  experiments,
}: {
  boundEditorSDK: BoundEditorSDK
  experiments: Experiments
}) => {
  const locale = await boundEditorSDK.environment.getLocale()
  editorAppMetaData.setLocaleData(new LocaleData(createI18n(locale)))
  return translations.init(locale, experiments.all())
}

const registerToCustomEvents = ({
  boundEditorSDK,
  experiments,
}: {
  boundEditorSDK: BoundEditorSDK
  experiments: Experiments
}) => {
  const eventTypes = ['pageDuplicated', 'componentDataChanged']

  return boundEditorSDK.document.application.registerToCustomEvents({
    eventTypes,
  })
}

const initApp = async ({
  appDefinitionId,
  editorSDK,
  origin,
  ravenInstance,
  fedopsLogger,
  httpClient,
}: {
  appDefinitionId: string
  editorSDK: EditorSDK
  origin: Origin
  ravenInstance
  fedopsLogger
  httpClient: IHttpClient
}) => {
  captureBreadcrumb({
    message: 'start initializing app api',
    category: 'init-app',
    level: 'info',
    data: {
      origin,
    },
  })

  const [msid, siteId, uuid, instance, experiments] = await Promise.all([
    editorSDK.info.getMetaSiteId(appDefinitionId),
    editorSDK.info.getSiteId(appDefinitionId),
    editorSDK.info.getUserId(appDefinitionId),
    editorSDK.info.getAppInstance(appDefinitionId),
    initExperiments(),
  ])

  updateMetaSiteId(msid)

  captureBreadcrumb({
    message: 'prepare api data',
    category: 'init-app',
    level: 'info',
    data: {
      msid,
      siteId,
    },
  })
  const boundEditorSDK: BoundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const collectionsApi = initCollectionsApi({
    editorSDK,
    appDefinitionId,
    httpClient,
    instance,
    experiments
  })
  const remoteApi = new RemoteApi({
    boundEditorSDK,
    experiments,
    ravenInstance,
    formServiceClient: new FormsServiceAmbassadorClient(boundEditorSDK, fedopsLogger, httpClient),
    httpClient,
    fedopsLogger,
  })
  const biLogger = initBiLoggerForEditorApp(msid, uuid, origin.type)
  await initTranslations({ boundEditorSDK, experiments })

  captureBreadcrumb({
    message: 'create core api instance',
    category: 'init-app',
    level: 'info',
  })

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance,
    biLogger,
    origin,
    appDefinitionId,
    fedopsLogger,
    siteId,
  })

  captureBreadcrumb({
    message: 'set app api',
    category: 'init-app',
    level: 'info',
  })

  await boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi())

  coreApi.appState.setState()
  boundEditorSDK.controllers.listAllControllers().then((controllers) => {
    coreApi.steps.updateMultiStepFormsTitles(controllers)
    coreApi.fields.changePaymentFieldsCurrency(controllers)
    coreApi.fields.connectFieldsToSubRoles(controllers)
  })

  editorAppMetaData.setCoreApi(coreApi)
  fetchFormsThemes(ravenInstance)
  fetchAddPanelPresets(ravenInstance)

  await registerToCustomEvents({ boundEditorSDK, experiments })

  // DO NOT wait for this (by design) - it's shouldn't be a blocker or fail the init
  coreApi.filterUnknownCountryCodes()

  captureBreadcrumb({
    message: 'log app loaded',
    category: 'init-app',
    level: 'info',
  })

  fedopsLogger.appLoaded()

  if (experiments.enabled('specs.crm.FormsEditorDevMode')) {
    const globalAny = global as any

    globalAny.boundEditorSDK = boundEditorSDK
    globalAny.coreApi = coreApi

    globalAny.convertSelectedForm = async () => {
      console.log('start converting selected contact form')

      const selectedGetSubscribersForm = (await boundEditorSDK.selection.getSelectedComponents())[0]
      console.log(selectedGetSubscribersForm)
      // await coreApi.addForm.addGetSubscribers(selectedGetSubscribersForm, true)
    }
  }

  captureBreadcrumb({
    message: 'initialized finished',
    category: 'init-app',
    level: 'info',
  })

  coreApi.lazyMigrations.applyAll()
  editorAppMetaData.isInitialized = true
}

// exported function
export const editorReady: EditorPlatformApp['editorReady'] = async (
  editorSDK,
  appDefinitionId,
  payload: EditorReadyOptions,
) => {
  try {
    const origin = payload.origin as Origin

    if (!editorAppMetaData.isInitialized) {
      const {
        monitoring,
        essentials: { httpClient },
      } = payload
      const { ravenInstance, fedopsLogger } = initMonitoring(monitoring)

      await initApp({
        appDefinitionId,
        editorSDK,
        origin,
        ravenInstance,
        fedopsLogger,
        httpClient,
      })
    }

    return startApp(origin)
  } catch (error) {
    handleError(error, {
      extra: { message: 'Editor ready failed to load' },
    })
  }
}
